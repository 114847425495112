::-webkit-scrollbar {
  width: 5px;
  border: 2px solid #fff;
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #000;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
}

::selection {
  background: gray;
  color: #fff;
}

::-moz-selection {
  background: gray;
  color: #fff;
}

::-webkit-selection {
  background: gray;
  color: #fff;
}

/* liens */

a,a:active,a:visited,a:hover {
  color: #828282;
  text-decoration: none;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

/* body */

body {
  color: #000;
  background: #f5f5f5;
  font-weight: 100;
  font-size: 9px;
  font-family: 'open sans',calibri,Helvetica,sans-serif;
  overflow-x: hidden;
  font-family: 'open sans',calibri,Helvetica,sans-serif;
}


#br {
  display: inline-block;
  margin-left: 380px;
  padding: 35px;
  width: 450px;
  color: #fff;
  text-align: center;
}

/* top bar */

.toptitle,h1 {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-family: 'Montserrat',Helvetica,cambria,sans-serif;
}

.toptitle {
  display: inline-block;
  margin-right: 10px;
  color: #fff;
  font-weight: 300;
  font-size: 16px;
  line-height: 60%;
}

h1 {
  color: #323232;
  font-weight: 400;
  font-size: 12px !important;
}

.margin-grid {
  margin-bottom: 20px;
}

.grid {
  position: fixed;
  top: 20px;
  bottom: 20px;
  left: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  /* padding: 0px 0px 600px 20px; */
  /* min-height: 100vh; */
  text-align: justify;
  line-height: 100%;
}

.grid-item {
  margin: 15px;
  width: 200px;
  height: 240px;
  background: #fff;
  color: black;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, .1);
}

.grid-item img {
  width: 200px;
  height: 200px;
  transition: all .3s ease-out;
}

.grid-item:hover img {
  filter: grayscale(100%);
}

.name {
  margin: 7px 0 3px 0;
  font-weight: bold;
  font-size: 11px;
  font-family: 'Montserrat',Helvetica,cambria,sans-serif;
  line-height:100%;
}

.long {
  margin: -1px 0;
  letter-spacing: 2px;
  font-weight: 300;
  font-size: 10px;
  font-family: 'Montserrat',Helvetica,cambria,sans-serif;
  line-height:100%;
}

.small {
  font-size: 10px;
}

.llong {
  margin: 4px 0;
  letter-spacing: 2px;
  font-weight: 300;
  font-size: 10px;
  font-family: 'Montserrat',Helvetica,cambria,sans-serif;
  line-height:100%;
}

.info {
  position: relative;
  margin: 15px 0;
  color: #fff;
  letter-spacing: 1px;
  font-size: 9px;
  font-family: 'Open Sans';
  line-height: 120%;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  padding: 40px;
  width: 200px;
  height: 100%;
  background: white;
  border: 1px solid #f3f3f3;
  padding-bottom: 300px;
}

.sidebar-overflow {
position: absolute;
width: 200px;
padding-right: 20px;
height: calc(100vh - 280px);
overflow: auto;
}

button {
  padding-left: 10px;
  outline: none;
  border: none;
  background: transparent;
  color: #828282;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  font-family: 'Open Sans',Helvetica,cambria,sans-serif;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
  cursor: pointer;
}

.button:active,.button.is-checked {
  color: #000;
  font-weight:600;
}

.button-group {
  margin-bottom: 20px;
}

.sidebar-title {
font-family: 'Montserrat',Helvetica,cambria,sans-serif;
font-size: 22px;
text-transform: uppercase;
}
.go-back {
font-size: 10px;
cursor: pointer;
margin-bottom: 20px;
}

.description {
font-size: 11px;
text-align: justify;
margin: 20px 0px;
}

.filter-title {
  background: #f8f8f8;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 15px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  border: 1px solid #f3f3f3;
  padding: 6px;
}

.color-circle {
width: 20px;
height: 20px;
border-radius: 20px;
border: 1px solid #f0f0f0;
}

#avatar-overlay {
top: 0;
left: 0;
width: 100vw;
height: 100vh;
position: fixed;
visibility: hidden;
/* transition: 0.3s all; */
z-index: 4;
}

#black-cover {
width: 100%;
height: 100%;
background:rgba(0, 0, 0, .3);
opacity: 0;
transition: 0.3s all;
cursor: pointer;
}

#avatar-block {
top: 0;
right: 0;
bottom: 0;
width: 800px;
height: 100vh;
margin-right: -800px;
position: absolute;
border: 1px solid #f3f3f3;
background: white;
z-index: 4;
box-sizing: border-box;
transition: 0.3s all ease-in-out;
padding: 30px;
overflow: auto;
text-transform: uppercase;
font-size: 11px;
font-family: 'open sans',calibri,Helvetica,sans-serif;
}

.infos-character {
padding: 20px;
background: #f8f8f8;
width: 700px;
display: flex;
flex-direction: row;
justify-content: space-around;
border: 1px solid #f3f3f3;
}

.infos-character img {
  width: 200px;
  height: 200px;
border: 10px solid white;
box-shadow: 1px 1px 2px rgba(0, 0, 0, .1);
}

.infos-character .line {
background: white;
width: 400px;
height: 40px;
margin-bottom: 20px;
box-sizing: border-box;
padding-left: 40px;
display: flex;
align-items: center;
box-shadow: 1px 1px 2px rgba(0, 0, 0, .1);
}

.info-row {
display: flex;
flex-direction: row;
justify-content: space-between;
width: 400px;
}

.info-avatar {
width: 100px;
height: 100px;
background: white;
box-shadow: 1px 1px 2px rgba(0, 0, 0, .1);
text-align: center;
display: flex;
flex-direction: column;
justify-content: center;
}

.info-number {
margin-top: -5px;
font-size: 32px;
font-weight: bold;
}

.info-tag-container {
width: 280px;
height: 100px;
background: white;
box-shadow: 1px 1px 2px rgba(0, 0, 0, .1);
box-sizing: border-box;
padding: 20px;
overflow: auto;
}

.tag {
display: inline-block;
padding: 5px;
border-radius: 5px;
font-weight: bold;
font-size: 8px;
margin-right: 5px;
margin-bottom: 5px;
background: #b7b7b7;
color: white;
}

.credit {
min-width: 170px;
height: 40px;
background: #f8f8f8;
box-sizing: border-box;
margin-top: 10px;
margin-bottom: 15px;
font-size: 10px;
text-transform: uppercase;
text-align: center;
font-weight: bold;
letter-spacing: 1px;
border: 1px solid #f3f3f3;
display: flex;
justify-content: center;
align-items: center;
flex: 1;
}

.avatar-title {
width: 740px;
background: #f8f8f8;
margin-top: 20px;
box-sizing: border-box;
margin-top: 30px;
margin-bottom: 15px;
font-size: 10px;
text-transform: uppercase;
font-weight: bold;
letter-spacing: 1px;
border: 1px solid #f3f3f3;
padding: 6px 60px;
}

.avatars {
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: start;
}

.avatar-container {
margin: 0px 20px;
}

.nb-ava {
width: 10px;
height: 10px;
position: absolute;
padding: 10px;
background: rgba(0, 0, 0, 0.8);
color: white;
border-radius: 5px 5px 0px 0px;
margin: -31px 0px 0px 160px;
font-weight: bold;
}

.partenariats {
position: fixed;
z-index: 4;
right: 0;
margin-top: 20px;
margin-right: -400px;
display: flex;
font-weight: 500;
flex-direction: row;
transition: 0.6s all;
pointer-events: none;
}

.partenariats:hover {
margin-right: 0px;
transition: 0.6s all;
}

.partenariats-bouton {
padding: 15px;
background: rgba(0, 0, 0, 0.8);
color: white;
font-size: 24px;
max-height: 36px;
border-radius: 10px 0px 0px 10px;
pointer-events: all;
}

.partenariats-container {
width: 400px;
max-height: 300px;
overflow: auto;
box-sizing: border-box;
padding: 20px;
border-radius: 0px 0px 0px 10px;
background: rgba(0, 0, 0, 0.8);
pointer-events: all;
}

.partenariats-title {
font-family: 'Montserrat',Helvetica,cambria,sans-serif;
font-size: 22px;
text-transform: uppercase;
color: white;
margin-bottom: 17px;
}

.row-container, .row-container a {
  font-size: 11px;
  font-family: 'Montserrat',Helvetica,cambria,sans-serif;
  line-height:100%;
  color: white;
  text-transform: uppercase;
}

.row-container img {
margin-right: 15px;
margin-bottom: 10px;
}

.row {
display: flex;
flex-direction: row;
  font-size: 11px;
  font-family: 'Montserrat',Helvetica,cambria,sans-serif;
  line-height:100%;
  color: white;
  text-transform: uppercase;
}

.sorting {
margin-top: 100px;
z-index: 3;
}

.sort-button {
margin: 0;
margin-bottom: 8px;
border: none;
display: inline-block;
cursor: pointer;
transition: 0.2s all;
border: none;
color: white;
padding: 0;
}

.sort-button:hover {
color: #f1b238;
transition: 0.2s all;
}


.button {
  padding-left: 10px;
  outline: none;
  border: none;
  background: transparent;
  color: #828282;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  font-family: 'Open Sans',Helvetica,cambria,sans-serif;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
  cursor: pointer;
}

.button:active,.button.is-checked {
  background: transparent;
  color: #000;
  font-weight:600;
}

.button-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.multicolor {
  background: url('https://i.imgur.com/DabqvUe.png'), white;
}

.color-circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid #f0f0f0;
}

.two-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}


.jaune { background: #e8ac2a;}
.beige { background: #e3bf96;}
.marron { background: #6a3e17;}
.noir { background: black;}
.gris { background: grey;}
.rose {background: #d5568f;}
.bleu {background: #37a9d8;}
.vert {background: #6ab843;}
.violet {background: #a049c9;}
.orange {background: #e4721e;}
.rouge {background: #dc2020;}