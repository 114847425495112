.body-bg {
  background: url('https://i.imgur.com/4qCDG6P.png') bottom left no-repeat fixed,
              url('https://i.imgur.com/o05zjDB.png') bottom right no-repeat fixed,
              /* url('https://i.imgur.com/EYG5geZ.png') bottom left no-repeat fixed, */
              url('https://i.imgur.com/mFudy20.png') bottom center no-repeat fixed,
              url('https://i.imgur.com/7hqQBji.png') bottom repeat-x fixed,
              #f34a50;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  color: white;
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
}

.container a, .container a:visited {
  color: white !important;
}

.content-overflow {
  /* display: flex; */
  flex: 2;
  /* flex-direction: column; */
  width: 100%;
  margin-top: 30px;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0px 40px;
  text-align: justify;
  font-weight: 500;
}

.mobile {
  /* display: flex;
  flex-direction: column; */
}

.mobile .content-overflow {
  /* display: flex;
  flex-direction: column; */
  /* flex: 2; */
  overflow: auto;
  max-height: 300px;
  /* height: 100%; */
  /* display: flex; */
}

.container strong {
  color: #7ab373;
}

.container em {
  color: #f34a50;
}

.title {
  font-family: 'Intro';
  color: white;
  font-size: 48px;
  letter-spacing: 7px;
  width: 652px;
  margin: auto;
  text-align: center;
  text-shadow: 2px 2px 0px black;
  padding-bottom: 10px;
  border-bottom: 3px solid #f34a50;
  transition: 0.5s all;
}

.mobile .title {
  width: 80%;
  font-size: 24px;
  flex: 1;
}

.title:hover {
  color: #dc2f2e;
  transition: 0.5s all;
}

.container .line {
  width: 652px;
  border-bottom: 1px solid #312d2f;
  margin: 30px 0px;
}

.mobile .line {
  width: 100%;
}

.container .line2 {
  width: 100%;
  border-bottom: 1px solid #312d2f;
  margin: 10px 0px;
}

.container .left-bg {
  position: fixed;
  margin-left: -592px;
  /* margin-top: -200px; */
  bottom: 0;
  width: 592px;
  height: 1080px;
  background: url('https://i.imgur.com/4qCDG6P.png') bottom left no-repeat fixed, url('https://i.imgur.com/EYG5geZ.png') no-repeat;
}

.container .right-bg {
  position: absolute;
  margin-right: -592px;
  margin-top: -200px;
  width: 592px;
  height: 1080px;
  background: url('https://i.imgur.com/v7BITeu.png') no-repeat;
}

.rip {
  /* margin-top: 50px; */
  width: 100%;
  height: 67px;
  background: url('https://i.imgur.com/73vxlS0.png') no-repeat;
}

.container {
  background: #231d20;
  /* height: 100%; */
  height: calc(95vh - 67px);
  max-height: calc(1000px - 67px);
  display: flex;
  flex-direction: column;
}

.container .navigation {
  display: flex;
  flex-direction: row;
  color: white;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 10px;
}

.mobile .navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: white;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 10px;
  flex: 1;
}

.container .nav-item {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.container .nav-img img {
  max-width: 16px;
  max-height: 16px;
  margin: 0px 10px;
}

.mobile .nav-img img {
  max-width: 10px;
  max-height: 10px;
}

.container .nav-name {
  font-weight: bold;
  text-transform: uppercase;
  /* visibility: hidden; */
  transition: 0.2s all;
  margin-right: 10px;
}

.mobile .nav-name {
  font-size: 10px;
}

.container .nav-name:hover {
  color: #f34a50;
}

.container .tab-container {
  display: flex;
  flex-direction: row;
}

.container .infos {
  flex: 2;
  /* background: red; */
  padding-right: 20px;
}

.container .infos-nb {
  font-family: 'Intro', sans-serif;
  font-size: 32px;
}

.mobile .infos-nb {
  font-size: 20px;
}

.container .partos {
  width: 652px;
  margin: 10px 0px 10px 40px;
  padding: 5px 0px;
  background-color: #f34a50;
  color: #231d20;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.container .partos:hover .partos-box, .partos-box:hover {
  max-height: 300px;
  margin-top: -145px;
  transition: 0.5s all;
  opacity: 1;
}

.partos-box {
  position: absolute;
  width: 652px;
  background-color: #f34a50;
  box-sizing: border-box;
  overflow: hidden;
  margin-top: 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  transition: 0.5s all;
  opacity: 0;
}

.container .row-container {
  display: flex;
  /* width: 100%; */
  flex-direction: row;
  justify-content: space-between;
}

.mobile .row-container {
  flex-direction: column;
}

.mobile .row-container img {
  height: 254px;
  width: 254px;
}

.container #credit-text {
  position: absolute;
  font-size: 11px;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: white;
  margin-top: 10px;
  width: 600px;
  text-align: center;
}

.container #credit-text a {
  color: white;
  text-decoration: none !important;
}

.container #credit-text a:visited {
  color: white;
  text-decoration: none !important;
}

.container .credits-container {
  text-indent: 0;
  height: 254px;
  width: 254px;
  flex: 2;
  overflow: auto;
  text-transform: lowercase;
  line-height: 1.3;
  text-align: left;
}

.emptyspace {
  width: 100%;
  /* height:  */
}

.partos-box img {
  border: 2px solid white;
  margin-right: 10px;
  margin-bottom: 5px;
}

.container .credits {
  margin-bottom: 15px;
  color: white;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.mobile .credits {
  margin-top: 20px;
}

.container .bestPartosContainer img {
  margin: 5px;
  border: 2px white solid;
}

.container .bestPartos {
  position: fixed;
  margin-left: 750px;
  bottom: 100px;
  background: url('https://i.imgur.com/q9oYXbB.png') no-repeat top left;
  width: 260px;
  height: 280px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.container .discord {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  position: fixed;
  margin-left: 750px;
  bottom: 450px;
  background: url('https://i.imgur.com/ScNms4C.png') no-repeat top left, url('https://i.imgur.com/Tksa0Zk.png') no-repeat bottom left;
  width: 200px;
  height: 180px;
  cursor: pointer;
}

.container .discordbox {
  width: 100px;
  color: white;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 18px;
}